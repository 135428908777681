import irisPalette from './palette';

/**
 * These tokens are for setting MUI theme defaults
 *
 * See src/theme/index.ts to see them used to overwrite the default
 * MUI theme: https://mui.com/customization/default-theme/
 */

// Primary theme values use brand colors and follow this convention:
// main = text/background, dark = background-strong-hover, light = border

// The remaining theme values follow the convention:
// main = text, dark = text-hover, light = background
const themePalette = {
  error: {
    contrastText: '#FFFFFF',
    dark: '#46000D',
    light: '#DF99A6',
    main: '#7B0016',
  },
  info: {
    contrastText: '#FFFFFF',
    dark: '#012740',
    light: '#CCDFEC',
    main: '#024470',
  },
  primary: {
    contrastText: '#FFFFFF',
    dark: '#0A535F',
    light: '#56A0AB',
    main: irisPalette.brand.background,
  },
  success: {
    contrastText: '#FFFFFF',
    dark: '#254134',
    light: '#DFECE6',
    main: '#41715C',
  },
  text: {
    disabled: irisPalette.neutral.textDisabled,
    primary: irisPalette.neutral.text,
    secondary: irisPalette.neutral.textWeak,
  },
  warning: {
    contrastText: '#FFFFFF',
    dark: '#664400',
    light: '#FFF7E6',
    main: '#805600',
  },
};

export { themePalette };
