import Container from '@mui/material/Container';
import styled from '@mui/system/styled';

const StyledMainContainer = styled(Container)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: '64px 60px 40px 60px',
  textAlign: 'center',
});

const Styled = {
  MainContainer: StyledMainContainer,
};

export default Styled;
