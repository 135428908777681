import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import styled from '@mui/system/styled';
import * as tokens from '@/theme/iris';

const StyledMenuContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  flexGrow: '1',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

const StyledMenuItemButton = styled(Button)({
  color: tokens.palette.neutral.white,
  display: 'block',
  marginBottom: tokens.spacing.xLarge,
  marginTop: tokens.spacing.xLarge,
  textTransform: 'none',
});

const StyledMobileMenu = styled(Menu)(({ theme }) => ({
  display: 'block',

  // Hide above the small breakpoint
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const StyledMobileMenuContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  paddingRight: tokens.spacing.xSmall,

  // Hide above the small breakpoint
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const StyledMobileMenuOpener = styled(IconButton)({
  color: 'inherit',
});

// MenuContainer

const Styled = {
  MenuContainer: StyledMenuContainer,
  MenuItemButton: StyledMenuItemButton,
  MobileMenu: StyledMobileMenu,
  MobileMenuContainer: StyledMobileMenuContainer,
  MobileMenuOpener: StyledMobileMenuOpener,
};

export default Styled;
