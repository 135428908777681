const config = () => ({
  /**
   * Define all of your feature's api endpoints here.
   */
  endpoints: {
    locationsPath: process.env.REACT_APP_PUBLIC_LOCATION_API_PATH,
  },
  /**
   * The API base url for all of this features endpoints.
   * This should come from the .env files accessed via process.env
   */
  target: process.env.REACT_APP_PUBLIC_LOCATION_API_BASE_URL,
});

module.exports = config;
