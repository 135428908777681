import { createBrowserRouter, Navigate } from 'react-router-dom';
import ProtectedRoute from '@/components/ProtectedRoute/ProtectedRoute';
import Login from '@/pages/Login/Login';
import Main from '@/pages/Main/Main';
import NotFound from '@/pages/NotFound';

/**
 * Router defines the application routes.
 * All routes other than login should wrap the route's component's with the ProtectedRoute.
 */
const Router = createBrowserRouter([
  {
    element: (
      <ProtectedRoute>
        <Main />
      </ProtectedRoute>
    ),
    path: '/',
  },
  {
    element: <Login />,
    path: '/login',
  },
  {
    element: <NotFound />,
    path: '/404',
  },
  {
    element: (
      <Navigate
        replace
        to="/404"
      />
    ),
    path: '*',
  },
]);

export default Router;
