import { getApiClient } from '@/api/util';
const { endpoints, target } = require('./config')();
const api = getApiClient(target);

/**
 * Gets the login url
 *
 * @returns Login url data
 */
export const getLoginUrl = async () => {
  const response = await api.get(endpoints.getLoginUrl);

  return response?.data;
};

/**
 * Gets a user
 *
 * @returns User data
 */
export const getUser = async () => {
  const response = await api.get(endpoints.getUser);

  return response?.data;
};
