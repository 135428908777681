import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider } from 'react-router-dom';
import Router from '@/Router';
import Alerts from '@/components/Alerts';
import { AlertsProvider } from '@/hooks/useAlert';
import { AuthProvider } from '@/hooks/useAuth';
import AppThemeProvider from '@/theme/AppThemeProvider';

/**
 * Main App component
 * The top level component implementing required providers such as theme, auth, and routing.
 *
 * @returns The rendered component
 */
const App = () => {
  /**
   * See documentation for QueryClient options, such as caching:
   * https://tanstack.com/query/v4/docs/react/reference/QueryClient
   */
  const queryClient = new QueryClient({
    logger: {
      // eslint-disable-next-line no-empty-function
      error: () => {},
      // eslint-disable-next-line no-console
      log: console.log,
      warn: console.warn,
    },
  });

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <AppThemeProvider>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AlertsProvider>
              <Alerts />
              <RouterProvider router={Router} />
            </AlertsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </AppThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
