import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import { fontSize } from '@/theme/iris';

const StyledAppName = styled(Typography)(({ theme }) => ({
  color: 'inherit',
  display: 'flex',
  fontWeight: 700,
  marginRight: theme.spacing(2),
  textDecoration: 'none',
  textTransform: 'uppercase',

  [theme.breakpoints.down('md')]: {
    '&.MuiTypography-root': {
      fontSize: `${fontSize.note}`,
    },
  },
}));

const Styled = {
  AppName: StyledAppName,
};

export default Styled;
