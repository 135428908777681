import AppName from '@/components/AppName';
import CCOLogo from '@/components/CCOLogo';
import Styled from './AppNameContainer.styles';

/**
 * AppNameContainer component
 *
 * @param {object} [props] - The component props
 * @param {string} [props.linkTo] - Route to link to. Default = '/' (app root)
 * @returns The rendered component
 */
const AppNameContainer = ({ linkTo = '/' }) => (
  <Styled.AppNameContainer
    data-testid="app-name-container"
    to={linkTo}
  >
    <CCOLogo />
    <AppName />
  </Styled.AppNameContainer>
);

export default AppNameContainer;
