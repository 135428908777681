/* eslint-disable sort-keys */
const spacing = {
  xxSmall: '0.25rem',
  xSmall: '0.5rem',
  small: '0.75rem',
  medium: '1rem',
  large: '1.5rem',
  xLarge: '2rem',
  xxLarge: '3rem',
};

export default spacing;
/* eslint-enable sort-keys */
