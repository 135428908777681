import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import styled from '@mui/system/styled';

const StyledMenu = styled(Menu)({
  marginTop: '45px',
});

const StyledUserMenuContainer = styled(Box)({
  flexGrow: '0',
});

const StyledUserMenuIconButton = styled(IconButton)({
  color: 'inherit',
  padding: '0',
});

const Styled = {
  Menu: StyledMenu,
  UserMenuContainer: StyledUserMenuContainer,
  UserMenuIconButton: StyledUserMenuIconButton,
};

export default Styled;
