import { dispatchCookieChangeEvent } from './events';

/**
 * Deletes the paseto cookie
 */
export const deletePasetoCookie = () => {
  const yesterday = new Date();

  yesterday.setDate(yesterday.getDate() - 1);
  const dateString = yesterday.toUTCString();
  const domain = window.location.hostname === 'localhost' ? 'localhost' : 'cco.dev';

  document.cookie = `cco.dev.paseto=; domain=${domain}; expires=${dateString}; path=/`;

  dispatchCookieChangeEvent();
};

/**
 * Gets and parses the auth paseto cookie if it exists.
 *
 * @returns the auth paseto cookie
 */
export const getPasetoCookie = () => {
  const cookieList = document.cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=');

    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});

  return cookieList['cco.dev.paseto'] || null;
};

/**
 * Checks an error object to see if it is an authentication error (401) or not.
 *
 * @param {Error} error - The error to check
 * @returns `true` if it is an axios authentication error, `false` if not
 */
export const isAuthError = (error) => 'response' in error && error.response?.status === 401;
