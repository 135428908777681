import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { keyframes, styled } from '@mui/system';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledAppBar = styled(AppBar)({
  zIndex: '9000',
});

const StyledLoading = styled(CircularProgress)({
  userSelect: 'none',
});

const StyledReactLogo = styled('img')({
  height: '40vmin',
  pointerEvents: 'none',
  userSelect: 'none',

  // eslint-disable-next-line sort-keys
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${spin} infinite 20s linear`,
  },
});

const StyledServerError = styled(Container)({
  marginTop: '2rem',
  textAlign: 'left',
});

const Styled = {
  AppBar: StyledAppBar,
  Loading: StyledLoading,
  ReactLogo: StyledReactLogo,
  ServerError: StyledServerError,
};

export default Styled;
