import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AppNameContainer from '@/components/AppNameContainer';
import MainAppBarMenu from '@/components/MainAppBarMenu';
import MainAppBarUserMenu from '@/components/MainAppBarUserMenu';
import { getPasetoCookie } from '@/util/auth.js';
import Styled from './MainAppBar.styles.jsx';

/**
 * This is the menu items to the main app bar.
 * Mobile items are collapsed under the "hamburger" icon menu, while
 * larger breakpoints fit them in the bar.
 */
const menuItems = [];

/**
 * This is the user menu items, you can add additional
 * items here, such as `'User Profile`' and add associated
 * actions below in the handleUserMenuItemClick handler function.
 */
const userMenuItems = [];

/**
 * Hides / shows on scroll
 *
 * @param {object} props - The component props
 * @param {JSX.Element} props.children - The component's children
 * @returns The rendered component
 */
const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide
      appear={true}
      direction="down"
      in={!trigger}
    >
      {children}
    </Slide>
  );
};

/**
 * Global app bar component
 */
const MainAppBar = () => (
  <HideOnScroll>
    <Styled.AppBar
      position="fixed"
      data-testid="main-app-bar"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AppNameContainer linkTo="/" />
          {getPasetoCookie() && (
            <>
              <MainAppBarMenu menuItems={menuItems} />
              <MainAppBarUserMenu menuItems={userMenuItems} />
            </>
          )}
        </Toolbar>
      </Container>
    </Styled.AppBar>
  </HideOnScroll>
);

export default MainAppBar;
