import AppBar from '@mui/material/AppBar';
import styled from '@mui/system/styled';

const StyledAppBar = styled(AppBar)({
  zIndex: '9000',
});

const Styled = {
  AppBar: StyledAppBar,
};

export default Styled;
