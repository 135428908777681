import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { irisTheme } from './iris';

/**
 * AppTheme Provider
 * The top level MUI theme provider
 *
 * @param {object} props - The component props
 * @param {JSX.Element} props.children - The child component(s) to render
 * @returns The rendered component
 */
const AppThemeProvider = ({ children }) => {
  const theme = responsiveFontSizes(createTheme(irisTheme));

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppThemeProvider;
