/**
 * Storage facacde class for localStorage and sessionStorage
 */
class Storage {
  /**
   * The Storage constructor.
   *
   * Ref: https://developer.mozilla.org/en-US/docs/Web/API/Storage
   *
   * @param {Storage} storage - The storage instance (LocalStorage | SessionStorage)
   * @param {string} name - 'LocalStorage' | 'SessionStorage'
   */
  constructor(storage, name) {
    this.name = name;
    this.storage = storage;
  }

  /**
   * Removes all key/value pairs, if there are any.
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  clear() {
    try {
      this.storage.clear();
    } catch (ex) {
      console.warn(`${this.name}.clear() Error`, ex);
    }
  }

  /**
   * Returns the current value associated with the given key, or null if the given key does not exist.
   *
   * @param {string} key - The key for the value in the storage to retrieve
   * @returns {string} The value, if it exists, undefined if not
   */
  getItem(key) {
    let value;

    try {
      value = this.storage.getItem(key);
    } catch (ex) {
      console.warn(`${this.name}.getItem('${key}') Error`, ex);
    }

    return value;
  }

  /**
   * Removes the key/value pair with the given key, if a key/value pair with the given key exists.
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   *
   * @param {string} key - The key for the value in the storage to remove
   */
  removeItem(key) {
    try {
      this.storage.removeItem(key);
    } catch (ex) {
      console.warn(`${this.name}.removeItem('${key}') Error`, ex);
    }
  }

  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   *
   * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set.
   * (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota has been exceeded.)
   *
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   *
   * @param {string} key - The key in the storage to set
   * @param {string} value - The value to set in the storage
   */
  setItem(key, value) {
    try {
      this.storage.setItem(key, value);
    } catch (ex) {
      console.warn(`${this.name}.setItem('${key}', '${value}') Error`, ex);
    }
  }
}

export const LocalStorage = new Storage(localStorage, 'LocalStorage');

export const SessionStorage = new Storage(sessionStorage, 'SessionStorage');
