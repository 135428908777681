/**
 * Dispatch an event for when a user is no longer authorized
 */
export const dispatchApiErrorEvent = (error) => {
  window.dispatchEvent(new CustomEvent('apierror', { detail: error }));
};

/**
 * Dispatches the cookie change event
 */
export const dispatchCookieChangeEvent = () => {
  window.dispatchEvent(new CustomEvent('cookiechange', { detail: document.cookie }));
};

/**
 * Dispatch an event for when a user is no longer authorized
 */
export const dispatchUserUnauthorized = () => {
  window.dispatchEvent(
    new CustomEvent('userunauthorized', {
      detail: new Error('You have been logged out because your credentials are no longer valid.'),
    }),
  );
};
