import styled from '@mui/system/styled';
import { Link } from 'react-router-dom';

const StyledAppNameContainer = styled(Link)({
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',

  // eslint-disable-next-line sort-keys
  '.MuiTypography-root': {
    color: '#fff',
  },
});

const Styled = {
  AppNameContainer: StyledAppNameContainer,
};

export default Styled;
