import styled from '@mui/system/styled';

const StyledCCOLogo = styled('svg')(({ theme }) => ({
  flex: '1 1 50%',
  marginTop: '.2rem',
  width: '80%',

  [theme.breakpoints.up('sm')]: {
    marginTop: '0',
    width: '100%',
  },
}));

const Styled = {
  CCOLogo: StyledCCOLogo,
};

export default Styled;
