import Styled from './MainContainer.styles';

/**
 * MainContainer component
 *
 * @param {object} props - The component props
 * @param {JSX.Element} props.children - The child component(s) to render
 * @returns The rendered component
 */
const MainContainer = ({ children }) => (
  <Styled.MainContainer
    component="main"
    data-testid="main-container"
    maxWidth="xl"
  >
    {children}
  </Styled.MainContainer>
);

export default MainContainer;
