import { useState } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import User from '@/components/User/User';
import useAuth from '@/hooks/useAuth';
import Styled from './MainAppBarUserMenu.styles.jsx';

export const mainAppBarUserMenuTestIdPrefix = 'main-appbar-user-menu';

/**
 * Global app bar user menu component
 *
 * @param {object} props - The component props
 * @param {MenuItem[]} [props.menuItems] - The menu items
 * @returns The rendered component
 *
 * @usage
 * This is where you can add user menu items to the main app bar.
 * User menu items are collapsed under the "user profile" icon menu.
 * If there are other userMenuItems, this is where you would
 * add logic based on the label, such as adding `'User Profile'`
 * which could route to a user profile page or open a user profile modal.
 * The logout menu item is by default, and will always appeear last.
 * If no menuItems are provided, only the logout menu item is in the user menu.
 *
 * Menu items are objects with additional properties, for example:
 * ```
 * const menuItems = [
 *   { id: 'main-appbar-user-menu-item-one', label: 'Menu Item One', onClick: () => {} },
 *   { id: 'main-appbar-user-menu-item-two', label: 'Menu Item Two', route: '/menu-item-two', routeOptions: {} },
 *   { id: 'main-appbar-user-menu-item-three', label: 'Menu Item Three', onClick: () => {}, route: '/menu-item-three', routeOptions: {} },
 * ];
 * ```
 */
const MainAppBarUserMenu = ({ menuItems = [] }) => {
  // ----------------------------------------
  // State

  const [menuAnchorElm, setMenuAnchorElm] = useState(null);

  // ----------------------------------------
  // Hooks

  const navigate = useNavigate();
  const { signout } = useAuth();

  // ----------------------------------------
  // Local variables

  const userMenuItems = [
    ...menuItems,
    // Add the logout menu item to the end of the end items array
    {
      id: 'logout',
      label: 'Logout',
      onClick: () => signout(true),
    },
  ];

  // ----------------------------------------
  // Helper functions & Event handlers

  /**
   * Closes of the mobile menu
   */
  const closeMenu = () => {
    setMenuAnchorElm(null);
  };

  /**
   * Opens the menu
   *
   * @param {MouseEvent} event - The `click` MouseEvent
   */
  const onMenuOpenerClick = (event) => {
    setMenuAnchorElm(event.currentTarget);
  };

  /**
   * Handles clicking the menu item.
   * Executes menu item's "action" (onClick and/or route).
   *
   * @param {object} menuItem - The menu item object
   * @param {string} menuItem.id - The menu item's id
   * @param {string} menuItem.label - The menu item's label
   * @param {Function} [menuItem.onClick] - The menu item's click event
   * @param {Function} [menuItem.route] - The menu item's route
   * @param {object} [menuItem.routeOptions] - The menu item's route options
   */
  const handleMenuItemClick = (menuItem) => {
    if (menuItem.onClick != null) {
      menuItem.onClick();
    }

    if (menuItem.route) {
      navigate(menuItem.route, menuItem.routeOptions);
    }
  };

  // Return the rendered component
  return (
    <Styled.UserMenuContainer data-testid={`${mainAppBarUserMenuTestIdPrefix}-container`}>
      <Tooltip title="Open user menu">
        <Styled.UserMenuIconButton
          aria-controls={mainAppBarUserMenuTestIdPrefix}
          aria-haspopup="true"
          aria-label="Account of current user"
          data-testid={`${mainAppBarUserMenuTestIdPrefix}-opener`}
          onClick={onMenuOpenerClick}
          size="large"
        >
          <User />
          <AccountCircle />
        </Styled.UserMenuIconButton>
      </Tooltip>
      <Styled.Menu
        anchorEl={menuAnchorElm}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        data-testid={mainAppBarUserMenuTestIdPrefix}
        id={mainAppBarUserMenuTestIdPrefix}
        keepMounted
        onClose={closeMenu}
        open={menuAnchorElm != null}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {userMenuItems.map((menuItem) => {
          const id = `${mainAppBarUserMenuTestIdPrefix}-item-${menuItem.id}`;

          return (
            <MenuItem
              data-testid={id}
              key={id}
              onClick={() => {
                closeMenu();
                handleMenuItemClick(menuItem);
              }}
            >
              <Typography textAlign="center">{menuItem.label}</Typography>
            </MenuItem>
          );
        })}
      </Styled.Menu>
    </Styled.UserMenuContainer>
  );
};

export default MainAppBarUserMenu;
