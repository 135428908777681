import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLoginUrl } from '@/api/login/api';
import { deletePasetoCookie, getPasetoCookie } from '@/util/auth';
import { SessionStorage } from '@/util/storage';

/**
 * The auth context
 *
 * NOTE - not to be consumned outside of this file.
 * We only export for unit tests
 */
export const AuthContext = createContext();

/**
 * AuthProvider
 *
 * @param {object} props - The provider props
 * @param {JSX.Element} props.children - The child nodes to render
 * @returns The rendered children wrapped in the auth context provider.
 */
export const AuthProvider = ({ children }) => {
  // ----------------------------------------
  // State

  const [paseto, setPaseto] = useState(getPasetoCookie());

  // ----------------------------------------
  // Callbacks

  /**
   * Event handler for the `cookiechange` custom event
   */
  const handleCookieChange = useCallback(() => {
    setPaseto(getPasetoCookie());
  }, []);

  // ----------------------------------------
  // Effects

  /**
   * EFFECT | Mount / Unmount Effect
   * Reacts to the AuthProvider component mount / unmount
   * Adds the cookiechange event listener and updates the context's
   */
  const mountUnmountEffect = () => {
    window.addEventListener('cookiechange', handleCookieChange);

    return () => {
      window.removeEventListener('cookiechange', handleCookieChange);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(mountUnmountEffect, []);

  // ----------------------------------------
  // Return the rendered provider component
  return <AuthContext.Provider value={{ paseto }}>{children}</AuthContext.Provider>;
};

/**
 * useAuth Hook
 * Example paseto cookie value:
 * document.cookie = `cco.dev.paseto=C3PO; expires=Fri, 23 Feb 2026 00:10:12 GMT; path=/`;
 *
 * @returns {object} The auth hook interface which contains the getPasetoCookie, signin, and signout methods
 */
export const useAuth = () => {
  // ----------------------------------------
  // Hooks

  const { paseto } = useContext(AuthContext);
  const navigate = useNavigate();

  // ----------------------------------------
  // Helper Functions

  /**
   * Sign in navigate users to sso service on success.
   */
  const signin = async () => {
    const data = await getLoginUrl();

    if (data != null) {
      window.location.assign(data.url || '');
    }
  };

  /**
   * Sign out expires the cookie and removes the value
   * Then redirects the unauthed user to the login page to
   * repeat the sso workflow again
   *
   * @param {boolean} [forceAppReload] - `true` to force an app reload by setting the location.href to `/login`,
   *  `false` to use the router to navigate to `/login`. Default is `false`
   */
  const signout = (forceAppReload = false) => {
    deletePasetoCookie();

    if (forceAppReload) {
      SessionStorage.removeItem('redirect');
    }

    navigate('/login', { replace: true });

    if (forceAppReload) {
      window.location.reload();
    }
  };

  // ----------------------------------------
  // Return the hook interface

  return {
    getPasetoCookie: () => paseto,
    signin,
    signout,
  };
};

export default useAuth;
