import MainAppBar from '@/components/MainAppBar';
import MainContainer from '@/components/MainContainer';
import Styled from './NotFound.styles';

/**
 * Generic component for routes that do not exist
 */
const NotFound = () => (
  <>
    <MainAppBar />
    <MainContainer>
      <Styled.Box data-testid="not-found-container">
        <Styled.Typography variant="h1">404</Styled.Typography>
        <Styled.Typography variant="p">
          The page you requested could not be found.
        </Styled.Typography>
      </Styled.Box>
    </MainContainer>
  </>
);

export default NotFound;
