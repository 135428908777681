import fontSize from './fontSize';
import fontWeight from './fontWeight';
import lineHeight from './lineHeight';

const fontWeightButton = 900;

const bodyFonts = {
  fontFamily: "'Lato','HelveticaNeue-Light', Helvetica, Arial, sans-serif",
};

const button = {
  ...bodyFonts,
  fontSize: fontSize.button,
  fontWeight: fontWeightButton,
  letterSpacing: '0.075rem',
  lineHeight: lineHeight.lineHeight1,
  textTransform: 'uppercase', // as const,
};

const headingFonts = {
  fontFamily: "'Poppins','HelveticaNeue-Light', Helvetica, Arial, sans-serif",
};

const h1 = {
  ...headingFonts,
  fontSize: fontSize.headline1,
  fontWeight: fontWeight.fontWeightNormal,
  lineHeight: lineHeight.lineHeight1,
};

const h2 = {
  ...headingFonts,
  fontSize: fontSize.headline2,
  fontWeight: fontWeight.fontWeightNormal,
  letterSpacing: '0.01125rem',
  lineHeight: lineHeight.lineHeight1,
};

const h3 = {
  ...headingFonts,
  fontSize: fontSize.headline3,
  fontWeight: fontWeight.fontWeightNormal,
  letterSpacing: '0.01125rem',
  lineHeight: lineHeight.lineHeight1,
};

const h4 = {
  ...headingFonts,
  fontSize: fontSize.headline4,
  fontWeight: fontWeight.fontWeightSemiBold,
  letterSpacing: '0.0125rem',
  lineHeight: lineHeight.lineHeight1,
};

const h5 = {
  ...headingFonts,
  fontSize: fontSize.headline5,
  fontWeight: fontWeight.fontWeightSemiBold,
  letterSpacing: '0.1rem',
  lineHeight: lineHeight.lineHeight1,
};

const h6 = {
  ...headingFonts,
  fontSize: fontSize.headline6,
  fontWeight: fontWeight.fontWeightBold,
  letterSpacing: '0.0875rem',
  lineHeight: lineHeight.lineHeight1,
};

const note = {
  ...bodyFonts,
  fontSize: fontSize.note,
  fontWeight: fontWeight.fontWeightNormal,
  letterSpacing: '0.05375rem',
  lineHeight: lineHeight.lineHeight4,
};

const p1 = {
  ...bodyFonts,
  fontSize: fontSize.paragraph1,
  fontWeight: fontWeight.fontWeightNormal,
  letterSpacing: '0.03125rem',
  lineHeight: lineHeight.lineHeight2,
};

const p2 = {
  ...bodyFonts,
  fontSize: fontSize.paragraph2,
  fontWeight: fontWeight.fontWeightNormal,
  letterSpacing: '0.0125rem',
  lineHeight: lineHeight.lineHeight3,
};

const strongNote = {
  ...bodyFonts,
  fontSize: fontSize.note,
  fontWeight: fontWeight.fontWeightBold,
  letterSpacing: '0.05375rem',
  lineHeight: lineHeight.lineHeight4,
  textTransform: 'uppercase', // as const,
};

const strongP1 = {
  ...bodyFonts,
  fontSize: fontSize.paragraph1,
  fontWeight: fontWeight.fontWeightBold,
  letterSpacing: '0.03125rem',
  lineHeight: lineHeight.lineHeight2,
};

const strongP2 = {
  ...bodyFonts,
  fontSize: fontSize.paragraph2,
  fontWeight: fontWeight.fontWeightBold,
  letterSpacing: '0.0125rem',
  lineHeight: lineHeight.lineHeight3,
};

const subtitle1 = {
  ...bodyFonts,
  fontSize: fontSize.subtitle1,
  fontWeight: fontWeight.fontWeightBold,
  letterSpacing: '0.03125rem',
  lineHeight: lineHeight.lineHeight2,
};

const subtitle2 = {
  ...bodyFonts,
  fontSize: fontSize.subtitle2,
  fontWeight: fontWeight.fontWeightExtraBold,
  letterSpacing: '0.0125rem',
  lineHeight: lineHeight.lineHeight3,
};

const typography = {
  bodyFonts,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  headingFonts,
  note,
  p1,
  p2,
  strongNote,
  strongP1,
  strongP2,
  subtitle1,
  subtitle2,
  textField: p1,
  textFieldLabel: h6,
};

export default typography;
