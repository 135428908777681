import { useState } from 'react';
import AddLinkSharpIcon from '@mui/icons-material/AddLinkSharp';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import LoopIcon from '@mui/icons-material/Loop';
import StartIcon from '@mui/icons-material/Start';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { createLocation, upsertLocation } from '@/api/location/api';
import MainAppBar from '@/components/MainAppBar';
import MainContainer from '@/components/MainContainer';

/**
 * Main component
 *
 * @returns The rendered component
 */
const Main = () => {
  const blankSpace = '\u00A0';

  // ------------------------------------------------
  // State
  const [form, setForm] = useState({ shortURL: '', slug: '', url: '' });
  const [isCopied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pattern = new RegExp(
    /^(http(s)?:\/\/)(www\.)?([a-z0-9-]+\.)+[a-z]{2,}(:[0-9]{1,5})?([?|/]{1}.*)?$/,
  );

  const checkURLIsValid = (str) => pattern.test(str);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // do not submit form if the URL is invalid
    if (!checkURLIsValid(form.url)) {
      return;
    }

    setIsLoading(true);
    setForm({ ...form, shortURL: '' });

    const data = { url: form.url };

    // upsert call used when a custom slug is provided
    if (form.slug) {
      data.slugs = [
        {
          slug: form.slug,
        },
      ];

      try {
        const res = await upsertLocation({ slug: form.slug }, data);

        setIsLoading(false);
        setShortURL(res);
      } catch (err) {
        console.error(err);
      }

      return;
    }

    // create call
    try {
      const res = await createLocation(data);

      setIsLoading(false);
      setShortURL(res);
    } catch (err) {
      console.error(err);
    }
  };

  const onChange = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value,
    }));
  };

  const onCopy = (e) => {
    e.preventDefault();

    if (!form.shortURL) {
      return;
    }

    // try deprecated approach first...
    document.execCommand('copy', false, form.shortURL);

    // worst case, try the new approach
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(form.shortURL).catch((err) => {
        console.error(err);
      });
    }

    // update state
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const setShortURL = (loc) => {
    if (!loc) {
      return;
    }

    // display the result
    if (loc.slugs?.length) {
      // retrieve the most recent slug
      const latest = loc.slugs.reduce(
        (next, current) => (next.createdAt > current.createdAt ? next : current),
        loc.slugs[0],
      );

      // set the short URL
      setForm({ ...form, shortURL: latest.shortURL });
    }
  };

  return (
    <>
      <MainAppBar />
      <MainContainer>
        <h1>get going with go</h1>
        <h2>Turn your long, ugly URL into a nice, short branded URL.</h2>
        <form onSubmit={handleSubmit}>
          <Grid
            alignItems="flex-start"
            container={true}
            justifyContent="flex-end"
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                required={true}
                label="URL"
                error={!checkURLIsValid(form.url)}
                name="url"
                data-testid="url-input"
                helperText={!checkURLIsValid(form.url) ? 'Please enter a valid URL' : 'Nice URL!'}
                fullWidth={true}
                onChange={onChange}
                value={form.url}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddLinkSharpIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                label="Customization"
                name="slug"
                fullWidth={true}
                helperText="Optional: customize your short URL"
                onChange={onChange}
                value={form.slug}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutoAwesomeSharpIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                label="dope URL"
                name="shortURL"
                helperText={
                  isCopied ? (
                    <Fade
                      in={isCopied}
                      timeout={1000}
                    >
                      <span>copied!</span>
                    </Fade>
                  ) : (
                    blankSpace
                  )
                }
                fullWidth={true}
                value={form.shortURL}
                InputProps={{
                  disabled: true,
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      {isLoading ? (
                        <LoopIcon
                          style={{
                            animation: 'spin 1s linear infinite',
                          }}
                        />
                      ) : (
                        <LoopIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                <StartIcon />
              </Button>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                aria-label="copy to clipboard"
                onClick={onCopy}
              >
                <ContentCopySharpIcon />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      </MainContainer>
    </>
  );
};

export default Main;
