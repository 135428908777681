import * as tokens from './designTokens';
import palette from './designTokens/palette';
import { themePalette } from './designTokens/themeDefaults';
import typography from './designTokens/typography';

const irisTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      a:link {
        color: ${palette.brand.text};
        text-decoration: none;
      }
      :root {
        ${typography.p1}
      }
      `,
    },
    // See MUI for adding new component variants: https://mui.com/customization/theme-components/#adding-new-component-variants
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          div: 'div',
        },
      },
      variants: [
        {
          props: { variant: 'note' },
          style: { ...typography.note },
        },
        {
          props: { variant: 'p1' },
          style: { ...typography.p1 },
        },
        {
          props: { variant: 'p2' },
          style: { ...typography.p2 },
        },
        {
          props: { variant: 'strongNote' },
          style: { ...typography.strongNote },
        },
        {
          props: { variant: 'strongP1' },
          style: { ...typography.strongP1 },
        },
        {
          props: { variant: 'strongP2' },
          style: { ...typography.strongP2 },
        },
      ],
    },
  },
  palette: {
    error: {
      contrastText: themePalette.error.contrastText,
      dark: themePalette.error.dark,
      light: themePalette.error.light,
      main: themePalette.error.main,
    },
    info: {
      contrastText: themePalette.info.contrastText,
      dark: themePalette.info.dark,
      light: themePalette.info.light,
      main: themePalette.info.main,
    },
    mode: 'light',
    primary: {
      contrastText: themePalette.primary.contrastText,
      dark: themePalette.primary.dark,
      light: themePalette.primary.light,
      main: themePalette.primary.main,
    },
    success: {
      contrastText: themePalette.success.contrastText,
      dark: themePalette.success.dark,
      light: themePalette.success.light,
      main: themePalette.success.main,
    },
    text: {
      disabled: themePalette.text.disabled,
      primary: themePalette.text.primary,
      secondary: themePalette.text.secondary,
    },
    warning: {
      contrastText: themePalette.warning.contrastText,
      dark: themePalette.warning.dark,
      light: themePalette.warning.light,
      main: themePalette.warning.main,
    },
  },
  typography: {
    body1: typography.p1,
    body2: typography.p2,
    button: typography.button,
    caption: typography.note,
    div: {
      ...tokens.typography.body1,
      fontWeight: 400,
      lineHeight: 1.2,
    },
    fontSize: 16,
    h1: typography.h1,
    h2: typography.h2,
    h3: typography.h3,
    h4: typography.h4,
    h5: typography.h5,
    h6: typography.h6,
    subtitle1: typography.subtitle1,
    subtitle2: typography.subtitle2,
  },
  // eslint-disable-next-line sort-keys
  tokens,
};

export { irisTheme };
