import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';

const StyledAlert = styled(Alert)({
  width: '100%',
});

const StyledAlertsContainer = styled(Box)({
  left: '50%',
  pointerEvents: 'none',
  position: 'fixed',
  top: '0',
  transform: 'translateX(-50%)',
  width: '50%',
  zIndex: '999999',
});

const StyledAlertContainer = styled(Box)({
  marginTop: '2px',
  pointerEvents: 'all',

  // eslint-disable-next-line sort-keys
  '&:first-of-type': {
    marginTop: '0',
  },
});

const Styled = {
  Alert: StyledAlert,
  AlertContainer: StyledAlertContainer,
  AlertsContainer: StyledAlertsContainer,
};

export default Styled;
