const palette = {
  brand: {
    background: '#0E7787',
    backgroundActive: '#CFE4E7',
    backgroundStrongHover: '#0A535F',
    backgroundWeakHover: '#E7F1F3',
    border: '#56A0AB',
    borderHover: '#0E7787',
    borderWeak: '#9FC9CF',
    borderWeakHover: '#56A0AB',
    icon: '#0E7787',
    iconHover: '#0A535F',
    text: '#0E7787',
  },
  danger: {
    background: '#DF99A6',
    backgroundActive: '#D88090',
    backgroundHover: '#D06679',
    border: '#C84D63',
    borderHover: '#B00020',
    icon: '#B00020',
    iconHover: '#7B0016',
    text: '#7B0016',
    textHover: '#46000D',
  },
  info: {
    background: '#CCDFEC',
    backgroundActive: '#B3D0E3',
    backgroundHover: '#9AC0D9',
    border: '#4F90BD',
    borderHover: '#0361A0',
    icon: '#0361A0',
    iconHover: '#024470',
    text: '#024470',
    textHover: '#012740',
  },
  neutral: {
    background: '#F3F3F3',
    backgroundActive: '#BCBDBD',
    backgroundButtonDisabled: '#DDDEDE',
    backgroundDisabled: '#EEEFEF',
    backgroundHover: '#F8F8F8',
    backgroundWeak: '#FFFFFF99', // Using alpha channel for 60% opacity
    border: '#EEEFEF',
    borderActive: '#BCBDBD',
    borderDisabled: '#E6E6E6',
    borderHover: '#CDCECE',
    borderStrong: '#DDDEDE',
    icon: '#828383',
    iconDisabled: '#B4B5B5',
    iconHover: '#232323',
    surface: '#FFFFFF',
    surfaceDisabled: '#F8F8F8',
    text: '#232323',
    textDisabled: '#ABADAD',
    textWeak: '#676868',
    white: '#FFFFFF',
  },
  success: {
    background: '#DFECE6',
    backgroundActive: '#BEDACD',
    backgroundHover: '#AED1C1',
    border: '#8EBEA8',
    borderHover: '#5DA283',
    icon: '#5DA283',
    iconHover: '#41715C',
    text: '#41715C',
    textHover: '#254134',
  },
  warning: {
    background: '#FFF7E6',
    backgroundActive: '#FFEECC',
    backgroundHover: '#FFE6B3',
    border: '#FFC44D',
    borderHover: '#FFAB00',
    icon: '#FFAB00',
    iconHover: '#CC8900',
    text: '#805600',
    textHover: '#664400',
  },
};

export default palette;
