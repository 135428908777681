import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@mui/system/styled';

const StyledErrorAlert = styled(Alert)({
  backgroundColor: 'transparent',
  color: '#fff',

  svg: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    marginTop: '0.125rem',
    padding: '0.125rem',
  },
});

const StyledWarningAlert = styled(Alert)({
  backgroundColor: 'transparent',
  color: '#fff',

  svg: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    marginTop: '0.125rem',
    padding: '0.063rem 0.125rem 0.125rem 0.125rem',
    transform: 'scale(1.2)',
  },
});

const StyledLoading = styled(CircularProgress)({
  marginRight: '0.625rem',
  maxHeight: '.7em',
  maxWidth: '.7em',
});

const StyledName = styled('div')({
  fontSize: '1rem',
  marginRight: '0.625rem',
});

const Styled = {
  ErrorAlert: StyledErrorAlert,
  Loading: StyledLoading,
  Name: StyledName,
  WarningAlert: StyledWarningAlert,
};

export default Styled;
