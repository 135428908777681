const fontSize = {
  button: '1rem',
  headline1: '2.026875rem',
  headline2: '1.801875rem',
  headline3: '1.601875rem',
  headline4: '1.42375rem',
  headline5: '1.26625rem',
  headline6: '1.125rem',
  note: '0.79rem',
  paragraph1: '1rem',
  paragraph2: '0.88875rem',
  subtitle1: '1rem',
  subtitle2: '0.88875rem',
};

export default fontSize;
