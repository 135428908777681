import { Box, styled, Typography } from '@mui/material';

const StyledBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StyledTypography = styled(Typography)({
  color: 'black',
});

const Styled = {
  Box: StyledBox,
  Typography: StyledTypography,
};

export default Styled;
