import { useQuery } from '@tanstack/react-query';
import { getUser } from '@/api/login/api';
import Styled from './User.styles';

/**
 * User component displays who is logged in.
 */
const User = () => {
  // ----------------------------------------
  // Hooks

  const {
    data: user,
    isError,
    isLoading,
  } = useQuery({
    cacheTime: 1000,
    queryFn: getUser,
    queryKey: ['user'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000,
  });

  // ----------------------------------------
  // Return the rendered component
  return (
    <>
      {isLoading ? (
        <Styled.Loading
          color="inherit"
          data-testid="username-loading"
        />
      ) : isError ? (
        <Styled.ErrorAlert
          data-testid="username-error"
          severity="error"
        >
          Error getting user data
        </Styled.ErrorAlert>
      ) : !user?.name ? (
        <Styled.WarningAlert
          data-testid="username-empty"
          severity="warning"
        >
          User data is empty
        </Styled.WarningAlert>
      ) : (
        <Styled.Name data-testid="username">{user.name}</Styled.Name>
      )}
    </>
  );
};

export default User;
