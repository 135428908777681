import { useCallback, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAlertDispatch } from '@/hooks/useAlert';
import useAuth from '@/hooks/useAuth';
import { SessionStorage } from '@/util/storage';

/**
 * Protected Route component
 * Wrapper component top renders child component(s) when the user is authentiacted,
 * otherwise, redirects to the login route.
 *
 * @param {object} props - The component props
 * @param {JSX.Element} props.children - The child component(s) to render
 * @returns The rendered component
 */
const ProtectedRoute = ({ children }) => {
  // ----------------------------------------
  // Hooks

  const { getPasetoCookie, signout } = useAuth();
  const alertDispatch = useAlertDispatch();
  const paseto = getPasetoCookie();
  const location = useLocation();
  const navigate = useNavigate();

  // ----------------------------------------
  // Local Variables

  const { pathname } = location;

  // ----------------------------------------
  // Callbacks

  /**
   * Event handler for the `apierror` custom event
   *
   * @param {CustomEvent} event - A custom event
   * @param {Error} event.detail - A an error object
   * @param {string} event.detail.message - The error message
   */
  const handleApiError = useCallback((event) => {
    alertDispatch({
      payload: {
        autoDismissDelay: 5000,
        message: event?.detail.message || 'Unknown API error',
        severity: 'error',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Event handler for the `userunauthorized` custom event
   *
   * @param {CustomEvent} event - A custom event
   * @param {Error} event.detail - A an error object
   * @param {string} event.detail.message - The error message
   */
  const handleUserUnauthorizedEvent = useCallback((event) => {
    alertDispatch({
      payload: {
        autoDismissDelay: 5000,
        message: event.detail.message,
        severity: 'error',
      },
    });
    signout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------
  // Effects

  /**
   * EFFECT | Mount / Unmount Effect
   * Reacts to the ProtectedRoute component mount / unmount.
   * Strips the token from the url.
   * Adds the userunauthorized event listener.
   * Adds the apierror event listener.
   */
  const mountUnmountEffect = () => {
    const queryParams = new URLSearchParams(location.search);
    let shouldRedirect = false;
    let redirect;

    window.addEventListener('userunauthorized', handleUserUnauthorizedEvent);
    window.addEventListener('apierror', handleApiError);

    // If we do not have a cookie yet and the attempted protected route is not a 404
    // set the pathname into session storage. This can happen when not authenticated
    // but trying to access a protected route. Else, get the redirect from session
    // storage, if any.
    if (!paseto && pathname !== '/404') {
      SessionStorage.setItem('redirect', pathname);
    } else {
      redirect = SessionStorage.getItem('redirect');

      if (redirect && redirect !== pathname) {
        shouldRedirect = true;
      }
    }

    // Remove the bearer token from the query string
    if (queryParams.has('bearer_token')) {
      queryParams.delete('bearer_token');
      shouldRedirect = true;
    }

    if (shouldRedirect && paseto) {
      navigate(redirect || pathname, {
        replace: true,
        search: queryParams.toString(),
      });

      // If we have a redirect, remove it from the session storage, but
      // out of the render cycle
      if (redirect) {
        setTimeout(() => {
          SessionStorage.removeItem('redirect');
        });
      }
    }

    return () => {
      window.removeEventListener('userunauthorized', handleUserUnauthorizedEvent);
      window.removeEventListener('apiError', handleApiError);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(mountUnmountEffect, []);

  // ----------------------------------------
  // Return the rendered component
  return paseto ? (
    <>{children}</>
  ) : (
    <Navigate
      to="/login"
      replace={true}
    />
  );
};

export default ProtectedRoute;
