import { getApiClient } from '@/api/util';
const { endpoints, target } = require('./config')();
const api = getApiClient(target);

/**
 * Gets Location data
 *
 * @param {object} options - axios request options
 * @returns specific Location data
 */
export const getLocationData = async (options) => {
  const endpoint = endpoints.locationsPath;
  const response = await api.get(endpoint, options);

  return response?.data;
};

/**
 * Creates a new location
 *
 * @param {object} data - location data
 * @param {object} options - axios request options
 * @returns location data
 */
export const createLocation = async (data, options) => {
  const response = await api.post(endpoints.locationsPath, data, options);

  return response?.data;
};

/**
 * Updates an existing location
 *
 * @param {object} templateParams
 * @param {object} data
 * @param {object} options
 * @returns location data
 */
export const upsertLocation = async (templateParams, data, options) => {
  const endpoint = `${endpoints.locationsPath}/${templateParams.slug}`;
  const response = await api.patch(endpoint, data, options);

  return response?.data;
};
