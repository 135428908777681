/**
 * Define your login api configs here
 */

const config = () => ({
  /**
   * Define all of your feature's api endpoints here.
   * Each feature's endpoints array of config objects with following properties:
   *
   * {key} id - the unique id of the endpoint, i.e. `'getUser'`
   * {string} endpoint - the API endpoint, i.e. `'/v1/user'`
   */
  endpoints: {
    getLoginUrl: process.env.REACT_APP_PUBLIC_LOGIN_API_APP_PATH,
    getUser: process.env.REACT_APP_PUBLIC_LOGIN_API_USER_PATH,
  },
  /**
   * The API base url for all of this features endpoints.
   * This should come from the .env files accessed via process.env
   */
  target: process.env.REACT_APP_PUBLIC_LOGIN_API_BASE_URL,
});

module.exports = config;
