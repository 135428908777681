import { useEffect } from 'react';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import useAlerts, { useAlertDispatch } from '@/hooks/useAlert';
import Styled from './Alerts.styles';

/**
 * Alerts component
 * Renders any current alerts
 *
 * @returns The rendered component
 */
const Alerts = () => {
  // ----------------------------------------
  // Hooks

  const alerts = useAlerts();
  const dispatch = useAlertDispatch();

  // ----------------------------------------
  // Helper Functions & Event Handlers

  const getAlertOnClose = (alert) =>
    alert.isDismissable ? () => dispatch({ payload: alert, type: 'dismiss' }) : undefined;

  // ----------------------------------------
  // Effects

  /**
   * EFFECT | Alerts Effect
   * Reacts to changes in the alerts
   */
  const alertsEffect = () => {
    alerts.forEach((alert) => {
      if (alert.isShown === false) {
        setTimeout(() => {
          dispatch({ payload: alert, type: 'destroy' });

          if (typeof alert.onClose === 'function') {
            alert.onClose();
          }
        }, 500);
      } else if (!alert.autoDismissPending && alert.autoDismissDelay != null) {
        setTimeout(() => {
          dispatch({ payload: alert, type: 'dismiss' });
        }, alert.autoDismissDelay);
      }
    });
  };

  useEffect(alertsEffect, [alerts, dispatch]);

  // Return the rendered component
  return (
    <Styled.AlertsContainer>
      {alerts.map((alert) => (
        <Styled.AlertContainer
          data-testid={alert.id}
          key={alert.id}
        >
          <Collapse in={alert.isShown}>
            <Styled.Alert
              onClose={getAlertOnClose(alert)}
              severity={alert.severity}
              variant="filled"
            >
              <AlertTitle>{`${alert.severity.charAt(0).toUpperCase()}${alert.severity.substr(
                1,
              )}`}</AlertTitle>
              <div dangerouslySetInnerHTML={{ __html: alert.message }} />
            </Styled.Alert>
          </Collapse>
        </Styled.AlertContainer>
      ))}
    </Styled.AlertsContainer>
  );
};

export default Alerts;
