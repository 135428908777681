import { createContext, useContext, useReducer } from 'react';

const AlertsContext = createContext();
const AlertsDispatchContext = createContext(null);

let nextId = 1;

/**
 * Hook that returns a dispatch context
 *
 * @returns The alert function
 */
export const useAlertDispatch = () => useContext(AlertsDispatchContext);

/**
 * Alerts reducer
 * Takes in a action and reduces state based on the action type
 *
 * @param {array} state - The current state (an array of alerts)
 * @param {object} action - The action
 * @param {array} action.payload - The new state (alert)
 * @param {string} action.type - The type of action: 'add' | 'dismiss' | 'destroy'
 * @returns The new state (an array of alerts)
 */
export const alertsReducer = (state, action) => {
  const type = action.type || 'add';

  switch (type) {
    case 'add': {
      if (!action.payload.id) {
        action.payload.id = `alert-${action.payload.severity}-${nextId++}`;
      }

      if (state.findIndex((alert) => alert.id === action.payload.id) !== -1) {
        return state;
      }

      return [
        ...state,
        { ...action.payload, isDismissable: action.payload.isDismissable ?? true, isShown: true },
      ];
    }
    case 'dismiss': {
      const newState = [...state];
      const alert = newState.find((a) => a.id === action.payload.id);

      if (alert != null) {
        alert.isShown = false;
      }

      return newState;
    }
    case 'destroy': {
      return state.filter((alert) => alert.id !== action.payload.id);
    }
    default: {
      throw Error(`Unknown action: ${type}`);
    }
  }
};

const initialAlerts = [];

/**
 * The alerts provider
 *
 * @param {object} props - The provider props
 * @param {JSX.Element} props.children - The child nodes to render
 * @returns The rendered children wrapped in the auth context provider.
 */
export const AlertsProvider = (props) => {
  const [alerts, dispatch] = useReducer(alertsReducer, props.value?.initialAlerts ?? initialAlerts);

  return (
    <AlertsContext.Provider value={alerts}>
      <AlertsDispatchContext.Provider value={dispatch}>
        {props.children}
      </AlertsDispatchContext.Provider>
    </AlertsContext.Provider>
  );
};

/**
 * Alerts hook
 *
 * @returns The alerts array
 */
const useAlerts = () => useContext(AlertsContext);

export default useAlerts;
