import Styled from './AppName.styles';

/**
 * AppName component
 *
 * @returns The rendered component
 */
const AppName = () => (
  <Styled.AppName
    component="div"
    data-testid="app-name"
    noWrap
  >
    {process.env.REACT_APP_APP_TITLE}
  </Styled.AppName>
);

export default AppName;
