import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Styled from './MainAppBarMenu.styles.jsx';

export const mainAppBarMenuTestIdPrefix = 'main-appbar-menu';
export const mainAppBarMobileMenuTestIdPrefix = 'main-mobile-appbar-menu';

/**
 * Global app bar menu component
 *
 * @param {object} props - The component props
 * @param {MenuItem[]} props.menuItems - The menu items
 * @returns The rendered component
 *
 * @usage
 * This is where you can add menu items to the main app bar.
 * Mobile items are collapsed under the "hamburger" icon menu, while
 * larger breakpoints fit them in the bar.
 *
 * Menu items are objects with additional properties, for example:
 * ```
 * const menuItems = [
 *   { id: 'main-appbar-menu-item-one', label: 'Menu Item One', onClick: () => {} },
 *   { id: 'main-appbar-menu-item-two', label: 'Menu Item Two', route: '/menu-item-two', routeOptions: {} },
 *   { id: 'main-appbar-menu-item-three', label: 'Menu Item Three', onClick: () => {}, route: '/menu-item-three', routeOptions: {} },
 * ];
 * ```
 */
const MainAppBarMenu = ({ menuItems }) => {
  // ----------------------------------------
  // State

  const [mobileMenuAnchorElm, setMobileMenuAnchorElm] = useState(null);

  // ----------------------------------------
  // Hooks

  const navigate = useNavigate();

  // ----------------------------------------
  // Helper functions & Event handlers

  /**
   * Closes of the mobile menu
   */
  const closeMobileMenu = () => {
    setMobileMenuAnchorElm(null);
  };

  /**
   * Handles clicking the menu item.
   * Executes menu item's "action" (onClick and/or route).
   *
   * @param {object} menuItem - The menu item object
   * @param {string} menuItem.id - The menu item's id
   * @param {string} menuItem.label - The menu item's label
   * @param {Function} [menuItem.onClick] - The menu item's click event
   * @param {Function} [menuItem.route] - The menu item's route
   * @param {object} [menuItem.routeOptions] - The menu item's route options
   */
  const handleMenuItemClick = (menuItem) => {
    if (menuItem.onClick != null) {
      menuItem.onClick();
    }

    if (menuItem.route) {
      navigate(menuItem.route, menuItem.routeOptions);
    }
  };

  /**
   * Opens the mobile menu
   *
   * @param {MouseEvent} event - The `click` MouseEvent
   */
  const onMobileMenuOpenerClick = (event) => {
    setMobileMenuAnchorElm(event.currentTarget);
  };

  // Return the rendered component
  return (
    <>
      {/* Mobile */}
      <Styled.MobileMenuContainer data-testid={`${mainAppBarMobileMenuTestIdPrefix}-container`}>
        {menuItems.length !== 0 && (
          <>
            <Styled.MobileMenuOpener
              aria-controls={mainAppBarMobileMenuTestIdPrefix}
              aria-haspopup="true"
              aria-label="opens the main menu"
              data-testid={`${mainAppBarMobileMenuTestIdPrefix}-opener`}
              onClick={onMobileMenuOpenerClick}
              size="large"
            >
              <MenuIcon />
            </Styled.MobileMenuOpener>

            <Styled.MobileMenu
              anchorEl={mobileMenuAnchorElm}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              data-testid={mainAppBarMobileMenuTestIdPrefix}
              id={mainAppBarMobileMenuTestIdPrefix}
              keepMounted
              open={mobileMenuAnchorElm != null}
              onClose={closeMobileMenu}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'top',
              }}
            >
              {menuItems.map((menuItem) => {
                const id = `${mainAppBarMobileMenuTestIdPrefix}-item-${menuItem.id}`;

                return (
                  <MenuItem
                    data-testid={id}
                    key={id}
                    onClick={() => {
                      closeMobileMenu();
                      handleMenuItemClick(menuItem);
                    }}
                  >
                    <Typography textAlign="center">{menuItem.label}</Typography>
                  </MenuItem>
                );
              })}
            </Styled.MobileMenu>
          </>
        )}
      </Styled.MobileMenuContainer>

      {/* Tablet+ */}
      <Styled.MenuContainer data-testid={`${mainAppBarMenuTestIdPrefix}-container`}>
        {menuItems.map((menuItem) => {
          const id = `${mainAppBarMenuTestIdPrefix}-item-${menuItem.id}`;

          return (
            <Styled.MenuItemButton
              data-testid={id}
              key={id}
              onClick={() => handleMenuItemClick(menuItem)}
            >
              {menuItem.label}
            </Styled.MenuItemButton>
          );
        })}
      </Styled.MenuContainer>
    </>
  );
};

export default MainAppBarMenu;
