import axios from 'axios';
import { getPasetoCookie, isAuthError } from '@/util/auth';
import { dispatchApiErrorEvent, dispatchUserUnauthorized } from '@/util/events';

/**
 * This file provides configuration settings and internal utility functions
 * used buy the API functionality.
 * DO NOT MODIFY
 */

// Set the axois default HTTP request timeout
axios.defaults.timeout = process.env.REACT_APP_API_REQUEST_TIMEOUT;

/**
 * Gets an API client
 *
 * @param {string} [apiBaseURL] - The API base url. Default = `'/'`
 *    When not on production environment, overridden with `'/'` to work with the proxy.
 * @param {boolean} [requiresAuth] - `true` if the API requires auth, `false` if not. Default = `true`
 * @param {Object} [headers] - optional headers. Default = `{}`
 * @returns The axios API client
 */
export const getApiClient = (apiBaseURL = '/', requiresAuth = true, headers = {}) => {
  // BaseURL needs to be the root in order to work with the proxy in local dev
  const baseURL = process.env.NODE_ENV !== 'development' ? apiBaseURL : '/';
  let transformRequest;

  if (requiresAuth) {
    // Instead of setting the authorization on the headers directly, we use
    // transformRequest so that each request gets the latest token from the cookie.
    transformRequest = [
      (data, currentHeaders) => {
        const paseto = getPasetoCookie();

        currentHeaders.Authorization = `Bearer ${paseto}`;

        return data;
      },
      // The default transformRequest needs to be added to handle
      // transforming of the optional data (form data, json string, stream, etc)
      ...axios.defaults.transformRequest,
    ];
  }

  const apiClient = axios.create({
    baseURL,
    headers,
    transformRequest,
  });

  /**
   * Intercept the response and check for the status code
   * Other status responses can be handled this way
   */
  apiClient.interceptors.response.use(
    (response) => {
      if (response.data == null) {
        console.warn(`Empty API response data calling "${response.request.responseURL}"`);
      }

      return response;
    },
    (error) => {
      if (isAuthError(error)) {
        dispatchUserUnauthorized();
      } else {
        dispatchApiErrorEvent(error);
      }

      return error;
    },
  );

  return apiClient;
};
