import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import MainAppBar from 'components/MainAppBar';
import { useNavigate } from 'react-router-dom';
import logo from '@/logo.svg';
import MainContainer from '@/components/MainContainer';
import { useAlertDispatch } from '@/hooks/useAlert';
import useAuth from '@/hooks/useAuth';
import Styled from './Login.styles';

/**
 * Login component
 *
 * @returns The rendered component
 */
const Login = () => {
  // ----------------------------------------
  // Local Variables

  const isLocalEnv = useMemo(
    () =>
      process.env.REACT_APP_ENV === 'local' &&
      (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'),
    [],
  );
  const hasRequiredEnvVariables = useMemo(
    () =>
      !!process.env.REACT_APP_PUBLIC_LOGIN_API_BASE_URL &&
      process.env.REACT_APP_PUBLIC_LOGIN_API_APP_PATH != null,
    [],
  );

  // ----------------------------------------
  // State

  const [isLoading, setIsLoading] = useState(false);
  const [hasServerSetupError, setHasServerSetupError] = useState(!hasRequiredEnvVariables);

  // ----------------------------------------
  // Hooks

  const { signin, getPasetoCookie } = useAuth();
  const navigate = useNavigate();
  const alertDispatch = useAlertDispatch();

  // ----------------------------------------
  // Callbacks

  /**
   * Event handler for the `apierror` custom event
   *
   * @param {CustomEvent} event - A custom event
   * @param {Error} event.detail - A an error object
   * @param {string} event.detail.message - The error message
   */
  const handleApiError = useCallback((event) => {
    const response = event.detail?.response ?? {};
    const { data: responseData, status, statusText } = response;
    const message = `It appears that there is a problem with your local login server.<br />${responseData}.<br />${statusText}: ${
      event.detail?.message || 'api error'
    }.`;

    setIsLoading(false);

    const payload = {
      id: `api-server-error-${status}`,
      message,
      severity: 'error',
    };

    if (isLocalEnv && (status === 404 || status === 504)) {
      setHasServerSetupError(true);

      payload.onClose = () => {
        setHasServerSetupError(false);
      };
    } else {
      payload.autoDismissDelay = 5000;
    }

    alertDispatch({ payload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------
  // Helper functions

  /**
   * Log in the user
   */
  const login = async () => {
    setIsLoading(true);
    await signin();
  };

  // ----------------------------------------
  // Effects

  /**
   * EFFECT | Mount effect
   *
   * On mount check for the paseto
   * and navigate to root if the user is authed
   */
  useEffect(() => {
    const paseto = getPasetoCookie();

    window.addEventListener('apierror', handleApiError);

    if (paseto) {
      navigate('/', { replace: true });
    }

    if (!hasRequiredEnvVariables) {
      alertDispatch({
        payload: {
          id: 'missing-required-env-alert',
          isDismissable: false,
          message: `It appears that you do not have you environment variables set up correctly.
            Please ensure that you have the .env.local file in the root of the project with the
            REACT_APP_PUBLIC_LOGIN_API_BASE_URL and REACT_APP_PUBLIC_LOGIN_API_APP_PATH
            variables set properly, per the README file.
          `,
          severity: 'error',
        },
      });
    }

    return () => {
      window.removeEventListener('apierror', handleApiError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------
  // Return the rendered component
  return (
    <>
      <MainAppBar />

      <MainContainer>
        <Styled.ReactLogo
          src={logo}
          alt="React logo"
        />

        <div>
          {isLoading ? (
            <Styled.Loading data-testid="login-loading" />
          ) : (
            <Button
              data-testid="login-button"
              disabled={hasServerSetupError}
              onClick={() => login()}
              variant="contained"
            >
              Login
            </Button>
          )}
        </div>
      </MainContainer>
    </>
  );
};

export default Login;
